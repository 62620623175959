import { ColDef, ICellEditorParams } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { defaultDateTimeString, minimumDate } from "../../../../../../enums";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { CommonColDefFieldNamesEnum, PhaseColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapProgressStatuses
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  PROGRESS_STATUS_COLUMN_CONFIG,
  PROGRESS_STATUS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/progressStatus/ProgressStatusColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { PhaseField } from "../../utils/GridFields";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import PhasesApi from "../../../../../../services/api/v2/phases/Phases.api";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import moment from "moment";
import { stringToMomentDateForComparison } from "../../utils/helpers";

export interface PhaseGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewPhases: boolean;
  columns: string[];
  onFieldUpdate: () => void;
}

export class PhaseGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: PhaseGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  onFieldUpdate: () => void;

  constructor(gridProps: PhaseGridColumnBuilderProps) {
    super(PhasesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.ProgressStatus]: () => this.buildProgressStatusColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.makeDeletable().setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildProgressStatusColumn = () => {
    const progressStatuses = mapProgressStatuses();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(AUTOCOMPLETE_COLUMN_CONFIG({ field: "progressStatus", headerName: I18n.t("grids.status") }))
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateProgressStatus(params.data.id, params.newValue.key);

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.progressStatus || !progressStatuses) return null;
        const progress = progressStatuses.find(e => e.key === params.data.progressStatus + "");

        if (!progress) return "";
        return <>{progress.label}</>;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(progressStatuses));

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.StartDate,
          headerName: I18n.t("grids.startDate")
        })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: (params: ICellEditorParams) => {
            let projectStartDate = moment(
              params.data[PhaseColDefFieldNamesEnum.Project][CommonColDefFieldNamesEnum.StartDate]
            );
            let projectEndDate = moment(
              params.data[PhaseColDefFieldNamesEnum.Project][CommonColDefFieldNamesEnum.ActualEndDate]
            );

            const projectPhases = params.data[PhaseColDefFieldNamesEnum.Project][PhaseColDefFieldNamesEnum.Phases];

            const defaultDate =
              this.findNextPossibleStartDate(projectPhases, projectStartDate, projectEndDate) || projectStartDate;
            return defaultDate;
          },
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            const projectPhases = params.data[PhaseColDefFieldNamesEnum.Project][PhaseColDefFieldNamesEnum.Phases];
            const startDate = moment(e);

            const project = params.data[PhaseColDefFieldNamesEnum.Project];

            const isStartDateValid =
              startDate.isBetween(
                project[CommonColDefFieldNamesEnum.StartDate],
                project[CommonColDefFieldNamesEnum.ActualEndDate],
                null,
                "[]"
              ) &&
              !projectPhases.some(
                phase =>
                  stringToMomentDateForComparison(phase.startDate) ===
                  stringToMomentDateForComparison(startDate.toString())
              );

            if (isStartDateValid) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  findNextPossibleStartDate = (
    projectPhases: { id: number; startDate: string | null }[],
    projectStartDate: moment.Moment,
    projectEndDate: moment.Moment
  ): moment.Moment | null => {
    const sortedPhases = projectPhases
      .filter(
        e =>
          !!e.startDate && stringToMomentDateForComparison(e.startDate) > stringToMomentDateForComparison(minimumDate)
      )
      .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

    let closestGapStart = null;

    for (let i = 0; i < sortedPhases.length; i++) {
      const currentPhase = sortedPhases[i].startDate;
      const nextPhase = sortedPhases[i + 1]?.startDate || projectStartDate;
      const gap = moment(nextPhase).diff(currentPhase, "days");

      if (gap !== 1) {
        const possibleStartDate = moment(currentPhase).clone().add(1, "day");

        if (possibleStartDate.isAfter(projectStartDate) && possibleStartDate.isBefore(projectEndDate)) {
          if (!closestGapStart || possibleStartDate.isBefore(closestGapStart)) {
            closestGapStart = possibleStartDate;
          }
        } else if (
          stringToMomentDateForComparison(nextPhase.toString()) ===
            stringToMomentDateForComparison(projectStartDate.toString()) &&
          stringToMomentDateForComparison(currentPhase.toString()) ===
            stringToMomentDateForComparison(projectStartDate.toString())
        ) {
          closestGapStart = projectStartDate.clone().add(1, "day");
        }
      }

      if (
        gap === 1 &&
        stringToMomentDateForComparison(nextPhase.toString()) ===
          stringToMomentDateForComparison(projectStartDate.toString())
      ) {
        closestGapStart = projectStartDate.clone().add(1, "day");
      }
    }

    return closestGapStart;
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(PhaseField.DESCRIPTION, entityId, text);
    this.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(PhaseField.NAME, entityId, text);
    this.onFieldUpdate();
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PhaseField.START_DATE, entityId, date);
    this.onFieldUpdate();
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(PhaseField.END_DATE, entityId, date);
    this.onFieldUpdate();
  };
  updateProgressStatus = async (entityId: number, data: any) => {
    await this.updateIdField(PhaseField.PROGRESS_STATUS, entityId, data);
    this.onFieldUpdate();
  };
}
